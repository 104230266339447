import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')];

export const server_loads = [];

export const dictionary = {
	"/": [4],
	"/canvas-selection": [5],
	"/canvases": [6],
	"/clients": [7],
	"/collaborative": [8],
	"/collaborative/[venue]/[id]": [9,[2]],
	"/drawings": [11],
	"/drawing/[id]": [10,[3]],
	"/files": [12],
	"/login": [13],
	"/logout": [14],
	"/remote": [15],
	"/remote/pro": [16],
	"/socketio-test": [17],
	"/users": [18]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};